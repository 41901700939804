import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import RailsNestedForm from "@stimulus-components/rails-nested-form";

const application = Application.start();
const controllers = import.meta.glob("../controllers/**/*.{js,ts}", {
  eager: true
});

registerControllers(application, controllers);
application.register("textarea-autogrow", TextareaAutogrow);
application.register("nested-form", RailsNestedForm);

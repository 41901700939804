import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hazardousSituation", "controlForm"];
  connect() {
    this.updateHazardousSituationHint();
  }

  updateHazardousSituationHint() {
    const hazardousSituation = this.hazardousSituationTarget;
    const hint = hazardousSituation.parentElement.querySelector(".form-text");

    hint.innerHTML = document.querySelector(`[data-hazard="${hazardousSituation.value}"]`).innerHTML;
  }
}
